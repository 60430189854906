import { FOLDER_ANCESTORS_FRAGMENT } from './fragments'
import { gql } from 'graphql-request'

export const GET_PRESET_LINK = gql`
  query getPresetLink($batchId: ID) {
    presetLink(batchId: $batchId) {
      redirectValue
      redirectType
    }
  }
`
export const GET_IS_COLLECTIBLE_BATCH = gql`
  query getIsCollectible($flowcodeId: ID, $batchId: ID) {
    collectible(flowcodeId: $flowcodeId, batchId: $batchId) {
      isCollectible
      redirectType
      redirectValue
    }
  }
`

export const GET_FLOWTAGS_QUERY = gql`
  query getflowtags($first: Int, $after: String, $creationSource: String) {
    me {
      flowcodes(first: $first, after: $after, creationSource: $creationSource) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            nickname
            activeLink {
              redirectValue
              shorturl
            }
          }
        }
      }
    }
  }
`

export const GET_FLOWTAG_COUNT = gql`
  query getUserInfo {
    me {
      flowtagCount
    }
  }
`

export const SEARCH_FLOWCODE_ASSET_LABELS_QUERY = gql`
  query getAssetLabels(
    $isRecommended: Boolean = false
    $offset: Int
    $before: String
    $after: String
    $first: Int = 15
    $last: Int
    $name_Icontains: String
  ) {
    assetLabels(
      isRecommended: $isRecommended
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      name_Icontains: $name_Icontains
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const GET_FOLDER_ANCESTORS = gql`
  query getFolderAncestors($directoryId: ID!) {
    directory(directoryId: $directoryId) {
      id
      name
      batchCount
      ancestors {
        ...FolderAncestors
      }
    }
  }
  ${FOLDER_ANCESTORS_FRAGMENT}
`

export const GET_FOLDER_CHILDREN = gql`
  query getFolderChildren(
    $parentDirectoryId: ID
    $first: Int
    $orderBy: String = "name"
    $after: String
  ) {
    directories(parentDirectoryId: $parentDirectoryId, orderDirs: $orderBy) {
      allDirs(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            name
            id
            descendantCount
            parent {
              name
              id
            }
          }
        }
      }
    }
  }
`

// Print Store
export const FETCH_PRINT_STORE_SHOPPING_CART = gql`
  query shoppingCart {
    shoppingCart {
      id
      countryCode
      postalCode
      items {
        itemId
        product {
          id
          name
          description
          imageUrls
          availableQuantity
          minOrderQuantity
          maxOrderQuantity
          orderQuantityIncrement
          handlingTimeBusinessDays
          shippingOptionIds
          currency
          priceId
          priceModel {
            priceType
            sortedTiers {
              quantity
              unitAmount
            }
          }
        }
        quantity
        batch {
          timeStamp
          lastModified
          id
          domain
          archived
          type
          nickname
          creationSource
          studioConfig {
            id
            configuration
          }
          activeLink {
            shorturl
          }
        }
        template {
          id
        }
        templateDetails
        dhProjectExportId
        dhProjectId
        dhTemplateId
      }
      shippingOptions {
        id
        name
        description
        doesSupportInternational
        currency
        price
        estimatedDeliveryDateMin
        estimatedDeliveryDateMax
        deliveryDaysRangeMin
        deliveryDaysRangeMax
        excludedDeliveryDaysOfWeek
      }
      handlingOptions {
        id
        name
        description
        productionDaysRangeMin
        productionDaysRangeMax
        pricePercentageIncrease
      }
    }
  }
`

export const FETCH_PRINT_STORE_PRODUCTS_AND_BATCH = gql`
  query getPrintStoreProducts($batchId: ID!) {
    printStoreProducts {
      products {
        id
        name
        imageUrls
        minOrderQuantity
        availableQuantity
        priceModel {
          priceType
          sortedTiers {
            quantity
            unitAmount
          }
        }
      }
    }
    batch(batchId: $batchId) {
      id
      nickname
    }
  }
`

export const FETCH_PRINT_STORE_PRODUCTS = gql`
  query getGenericPrintStoreProducts {
    printStoreProducts {
      products {
        id
        name
        imageUrls
        minOrderQuantity
        availableQuantity
        priceModel {
          priceType
          sortedTiers {
            quantity
            unitAmount
          }
        }
      }
    }
  }
`

export const FETCH_PRINT_STORE_PRODUCT_DETAILS_AND_BATCH = gql`
  query printStoreProductWithBatch($productId: String, $batchId: ID) {
    printStoreProduct(productId: $productId) {
      product {
        id
        name
        headerText
        description
        isCustomizable
        imageUrls
        minOrderQuantity
        maxOrderQuantity
        orderQuantityIncrement
        availableQuantity
        handlingTimeBusinessDays
        currency
        priceId
        priceModel {
          priceType
          sortedTiers {
            quantity
            unitAmount
          }
        }
      }
      shippingOptions {
        id
        name
        description
        deliveryDaysRangeMin
        deliveryDaysRangeMax
        excludedDeliveryDaysOfWeek
        doesSupportInternational
        currency
        price
      }
    }
    batch(batchId: $batchId) {
      id
      nickname
      activeLink {
        shorturl
        redirectValue
        redirectType
      }
      studioConfig {
        id
        configuration
      }
    }
  }
`

export const FETCH_PRINT_STORE_PRODUCT_DETAILS = gql`
  query printStoreProduct($productId: String) {
    printStoreProduct(productId: $productId) {
      product {
        id
        name
        headerText
        description
        isCustomizable
        imageUrls
        minOrderQuantity
        maxOrderQuantity
        orderQuantityIncrement
        availableQuantity
        handlingTimeBusinessDays
        currency
        priceId
        priceModel {
          priceType
          sortedTiers {
            quantity
            unitAmount
          }
        }
      }
      shippingOptions {
        id
        name
        description
        deliveryDaysRangeMin
        deliveryDaysRangeMax
        excludedDeliveryDaysOfWeek
        doesSupportInternational
        currency
        price
      }
    }
  }
`

export const FETCH_PRINT_STORE_DISCOUNTS = gql`
  query getPrintStoreDiscounts {
    printStoreDiscountsByPlan {
      freeDiscount {
        percent
      }
      proDiscount {
        percent
      }
      proplusDiscount {
        percent
      }
      proflexDiscount {
        percent
      }
      enterpriseDiscount {
        percent
      }
    }
  }
`

export const GET_ESTIMATED_DELIVERY_DATE = gql`
  query estimatedDeliveryDate($handlingOptionId: ID!, $shippingOptionId: ID!) {
    estimatedDeliveryDate(
      handlingOptionId: $handlingOptionId
      shippingOptionId: $shippingOptionId
    ) {
      estimatedDeliveryDateMin
      estimatedDeliveryDateMax
    }
  }
`

export const GET_DOMAIN_MANAGEMENT_DETAILS = gql`
  query getDomainManagementDetails {
    domainManagementDetails {
      id
      domain
      type
      deletedAt
      flowcodeSubdomains {
        id
        domain
        timestamp
        isVerified
        deletedAt
      }
      flowpageSubdomains {
        id
        domain
        timestamp
        isVerified
        deletedAt
      }
    }
  }
`

export const FLOWPAGE_FLOWCODE_DOWNLOAD_DETAILS = gql`
  query flowpageFlowcodeDownloadDetails($id: ID!) {
    batch(batchId: $id) {
      archived
      studioConfig {
        id
        configuration
      }
      activeLink {
        shorturl
      }
    }
  }
`

export const GET_FLOWCODE_SUBDOMAINS = gql`
  query whiteLabelRedirectDomains {
    whiteLabelRedirectDomains {
      domain
      id
      isCoBranded
      isDefault
      timestamp
    }
  }
`

export const GET_REDIRECT_SUBDOMAIN_PERMISSIONS = gql`
  query getRedirectSubdomainPermissions($subdomainIds: [UUID]) {
    redirectSubdomainPermissions(subdomainIds: $subdomainIds) {
      assetId
      result {
        success
        error
      }
      entityPermissions {
        entityId
        entityType
        permissionName
        entityName
      }
    }
  }
`

export const GET_BATCHES_FOR_SUBDOMAINS = gql`
  query getBatchesForSubdomains($subdomainIds: [UUID]) {
    batchesForSubdomains(subdomainIds: $subdomainIds) {
      id
    }
  }
`

export const FLOWCODE_COUNT_QUERY = gql`
  query flowcodeCount {
    me {
      flowcodeCount
    }
  }
`

export const PRINT_STORE_PAGINATED_PRODUCTS_LIST = gql`
  query printStorePaginatedProducts(
    $productIds: [String]
    $productCategoryIds: [String]
    $limit: Int
    $after: String
  ) {
    printStorePaginatedProducts(
      productIds: $productIds
      productCategoryIds: $productCategoryIds
      limit: $limit
      after: $after
    ) {
      products {
        id
        name
        imageUrls
        priceModel {
          sortedTiers {
            quantity
            unitAmount
          }
        }
      }
      paginationInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`
export const PRINT_STORE_CATEGORIES_LIST_QUERY = gql`
  query printStoreProductCategoriesList(
    $productCategoryIds: [String]
    $parentCategoryIds: [String]
    $limit: Int
    $after: String
    $excludeChildCategories: Boolean
  ) {
    printStoreProductCategories(
      productCategoryIds: $productCategoryIds
      parentCategoryIds: $parentCategoryIds
      limit: $limit
      after: $after
      excludeChildCategories: $excludeChildCategories
    ) {
      productCategories {
        id
        name
        description
        tileImageUrl
        isTopLevelCategory
      }
      paginationInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`
export const PRINT_STORE_PRODUCTS_SUB_CATEGORY_IDS_QUERY = gql`
  query printStoreProductSubCategoryIds($parentCategoryIds: [String] = []) {
    printStoreProductCategories(parentCategoryIds: $parentCategoryIds) {
      productCategories {
        id
      }
    }
  }
`

export const PRINT_STORE_TOP_CATEGORY_QUERY = gql`
  query printStoreProductTopCategory($productCategoryIds: [String] = []) {
    printStoreProductCategories(productCategoryIds: $productCategoryIds) {
      productCategories {
        id
        name
        productBannerImageUrls
        productBannerText
      }
    }
  }
`

export const PRINT_STORE_TEMPLATES_BANNER_QUERY = gql`
  query printStoreProductTemplatesBanner($productCategoryIds: [String] = []) {
    printStoreProductCategories(productCategoryIds: $productCategoryIds) {
      productCategories {
        id
        name
        templateBannerImageUrls
        templateBannerText
      }
    }
  }
`

export const PRINT_STORE_CATEGORY_PRODUCTS_LIST_QUERY = gql`
  query printStoreCategoryProductsList(
    $productCategoryIds: [String]
    $maxProductsPerCategory: Int
    $limit: Int
    $after: String
  ) {
    printStoreCategoryProductMappings(
      productCategoryIds: $productCategoryIds
      maxProductsPerCategory: $maxProductsPerCategory
      limit: $limit
      after: $after
    ) {
      categoryProductMappings {
        id
        name
        hasMoreProducts
        products {
          id
          name
          imageUrls
          priceModel {
            sortedTiers {
              quantity
              unitAmount
            }
          }
          isCustomizable
        }
      }
      paginationInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`

export const PRINT_STORE_BRAND_KIT_QUERY = gql`
  query printStoreBrandKit {
    getBrandKit {
      primaryColor
      secondaryColor
      primaryText
      logoImage
    }
  }
`

export const PRINT_STORE_SINGLE_TEMPLATE_QUERY = gql`
  query printStoreSingleTemplate($templateId: UUID!) {
    getTemplate(templateId: $templateId) {
      templateId
      dhTemplateId
      name
      width
      height
      dimensionUnit
    }
  }
`

export const PRINT_STORE_MULTIPLE_TEMPLATES_QUERY = gql`
  query printStoreMultipleTemplates(
    $productId: UUID
    $categoryIds: [UUID] = []
    $layouts: [Layout]
    $fetchProducts: Boolean
    $limit: Int!
    $after: String!
  ) {
    getTemplates(
      productId: $productId
      categoryIds: $categoryIds
      layouts: $layouts
      fetchProducts: $fetchProducts
      limit: $limit
      after: $after
    ) {
      templates {
        templateId
        dhTemplateId
        name
        height
        width
        dimensionUnit
        products {
          productId
          name
          categoryIds
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

export const PRINT_STORE_PREVIEW_IMAGE_URL = gql`
  query printStorePreviewImageUrl($dhProjectId: String!, $format: ExportFormat) {
    getImageUrl(dhProjectId: $dhProjectId, format: $format) {
      imageUrl
    }
  }
`
export const PRINT_STORE_TEMP_PREVIEW_IMAGE_URL = gql`
  query printStoreTempPreviewImageUrl($dhProjectId: String!, $format: ExportFormat) {
    getTemporaryImageUrl(dhProjectId: $dhProjectId, format: $format) {
      imageUrl
    }
  }
`

export const PRINT_STORE_CATEGORIES = gql`
  query printStoreCategories($after: String!, $limit: Int!) {
    getCategories(after: $after, limit: $limit) {
      categories {
        categoryId
        categoryName
        sectionName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
export const CART_POPULAR_ITEMS_PRODUCTS_LIST = gql`
  query cartPopularItemsProductsList($limit: Int, $sortBy: String) {
    printStorePaginatedProducts(limit: $limit, sortBy: $sortBy) {
      products {
        id
        name
        headerText
        imageUrls
        isCustomizable
        categoryIds
      }
    }
  }
`

export const GET_TEMPLATES_BRAND_KIT_FLOWCODE_DETAILS = gql`
  query getTemplatesBrandKitFlowcodeDetails($batchId: ID!) {
    batch(batchId: $batchId) {
      id
      nickname
      type
      activeLink {
        id
        redirectValue
        shorturl
        redirectType
      }
      domain
      lastModified
      timeStamp
      analytics {
        lastScanDate
      }
      studioConfig {
        id
        configuration
        isCustomizable
        source
        name
      }
      batchOwner {
        isOwner
        user {
          ithacaId
          fullName
        }
      }
    }
  }
`

export const GET_ARCHIVED_FLOWCODES_COUNT = /* GraphQL */ `
  query CodeManagement($allbatchesArchived: Boolean = true, $pageType: PageType) {
    myFlowcodes(allbatchesArchived: $allbatchesArchived, pageType: $pageType) {
      allBatches {
        totalCount
      }
    }
  }
`

export const GET_DISPLAY_TO_ALL_FLOWCODE_TEMPLATES = gql`
  query displayToAllFlowcodeTemplates(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $nameIstartswith: String
    $nameIcontains: String
    $id: UUID
    $publishedOnIsnull: Boolean
    $source: String
    $orderBy: String
  ) {
    defaultFlowcodeTemplates(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      name_Istartswith: $nameIstartswith
      name_Icontains: $nameIcontains
      id: $id
      publishedOn_Isnull: $publishedOnIsnull
      source: $source
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          configuration
          displayToAll
          isCustomizable
          source
        }
        cursor
      }
      totalCount
    }
  }
`

export const GET_ASSIGNED_FLOWCODE_TEMPLATES = gql`
  query getAssignedFlowcodeTemplates(
    $entityTypesFilter: [EntityTypes]
    $includeDefaultTemplates: Boolean
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $nameIstartswith: String
    $nameIcontains: String
    $id: UUID
    $publishedOnIsNull: Boolean
    $source: String
    $orderBy: String
    $isCustomizable: Boolean
  ) {
    assignedFlowcodeTemplates(
      entityTypesFilter: $entityTypesFilter
      includeDefaultTemplates: $includeDefaultTemplates
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      name_Istartswith: $nameIstartswith
      name_Icontains: $nameIcontains
      id: $id
      publishedOn_Isnull: $publishedOnIsNull
      source: $source
      orderBy: $orderBy
      isCustomizable: $isCustomizable
    ) {
      edges {
        node {
          id
          name
          configuration
          assetFile {
            id
            assetName
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

export const GET_USER_SAVED_FLOWCODE_TEMPLATES = gql`
  query getUserSavedFlowcodeTemplates(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $orderBy: String
  ) {
    userSavedFlowcodeTemplates(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          name
          configuration
          timeStamp
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

export const GET_NESTED_DIRECTORIES = gql`
  query allNestedDirectories {
    allNestedDirectories {
      nestedDirs {
        name
        id
        isRoot
        children {
          name
          id
        }
      }
    }
  }
`

export const GET_FLOWCODE_TEMPLATES_COUNT = gql`
  query getFlowcodeTemplatesCount(
    $entityTypesFilter: [EntityTypes]
    $includeDefaultTemplates: Boolean
    $isCustomizable: Boolean
  ) {
    userSavedFlowcodeTemplates {
      totalCount
    }
    assignedFlowcodeTemplates(
      entityTypesFilter: $entityTypesFilter
      includeDefaultTemplates: $includeDefaultTemplates
      isCustomizable: $isCustomizable
    ) {
      totalCount
    }
  }
`
